<template>
  <v-parallax
      :src="require('../public/img/parallax-backgrounds/' + this.selectedImage)"
      height="100%"
      width="100%"
  >
    <v-app style="background: transparent">
      <v-app-bar
        app
        dark
      >
        <v-btn v-ripple text right to="/">Home</v-btn>
        <v-btn v-ripple text right to="/search">Search</v-btn>
        <v-btn v-ripple text right to="/list">List</v-btn>
        <v-btn v-ripple text right to="/schedule">Schedule</v-btn>
        <v-btn v-ripple text right to="/notes">Notes</v-btn>
        <v-btn v-ripple text right v-if="isLoggedIn" @click="logout">Logout</v-btn>

      </v-app-bar>
      <v-main color="transparent">
        <router-view/>
      </v-main>
    </v-app>
  </v-parallax>
</template>

<script>
import { Auth } from "aws-amplify"
import store from "@/store/store";
export default {
  name: 'App',

  data() {
    return {
      selectedImage: '',
      images: []

    }
  },
  watch: {
    // eslint-disable-next-line no-unused-vars
    '$route' (to, from) {
      document.title = to.meta.title || 'Anime Cave'
    }
  },
  computed: {
    isLoggedIn: function () {
      return store.state.isAuthenticated
    },
  },
  methods: {
    async logout() {
      try {
        Auth.signOut().then(
            store.commit("setAuthenticated", false),
            store.commit("setAuthenticatedUser", "")
        ).catch(error => {
          console.log(error)
        });
      } catch (error) {
        console.log('error signing out: ', error)
      }
    },
    getImages() {
      const illustrations = require.context('@/../public/img/parallax-backgrounds/', false, /^.*\.jpg$/)
      for(let i = 0; i < illustrations.keys().length; i++) {
        this.images.push(illustrations.keys()[i].substring(2))
      }
    },
    randomImage(images) {
      return images[Math.floor(Math.random() * images.length)];
    }
  },
  created() {
    this.getImages();
    this.selectedImage = this.randomImage(this.images)
  }
};
</script>
