/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-west-1",
    "aws_cognito_identity_pool_id": "eu-west-1:4c2b1b27-1d2a-40cc-899f-f41c04ed74f1",
    "aws_cognito_region": "eu-west-1",
    "aws_user_pools_id": "eu-west-1_2JYTZiMJu",
    "aws_user_pools_web_client_id": "69kpkatr25mkc2cjqcj9lc2ohf",
    "oauth": {}
};


export default awsmobile;
