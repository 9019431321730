import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import vuetify from './plugins/vuetify';
// eslint-disable-next-line no-unused-vars
import { Amplify, Auth } from 'aws-amplify';
import awsconfig from './aws-exports';
import axios from "axios";

Amplify.configure(awsconfig)

Vue.config.productionTip = false
Vue.prototype.$http = axios;

// const cognitoUser = Auth.currentAuthenticatedUser()
// if (AWS.config.credentials.needsRefresh()) {
//   const { refreshToken } = cognitoUser.getSignInUserSession();
//   cognitoUser.refreshSession(refreshToken, (err, session) => {
//     localStorage.setItem("vue-token", session.accessToken.jwtToken)
//     localStorage.setItem("vue-refresh", session.refreshToken.token)
//   })
// }

new Vue({
  router,
  vuetify,
  render: h => h(App)
}).$mount('#app')
