import Vue from 'vue';
import Vuex from 'vuex';
import createPersistedState from 'vuex-persistedstate';

Vue.use(Vuex)

export default new Vuex.Store({
    plugins: [createPersistedState({
        paths: ['isAuthenticated', "authenticatedUser"]
    })],
    state: {
        isAuthenticated: false,
        authenticatedUser: ""
    },
    mutations: {
        setAuthenticated (state, status) {
            state.isAuthenticated = status
        },
        setAuthenticatedUser (state, user) {
            state.authenticatedUser = user
        }
    }
})