import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import store from '@/store/store'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: {
      title: 'Anime Cave',
    }
  },
  {
    path: '/search',
    name: 'Search',
    component: () => import(/* webpackChunkName: "about" */ '../views/Search.vue'),
    meta: {
      title: 'Anime Search',
      requiresAuth: true
    }
  },
  {
    path: '/list',
    name: 'AnimeList',
    component: () => import(/* webpackChunkName: "about" */ '../views/AnimeList.vue'),
    meta: {
      title: 'Anime List',
      requiresAuth: true
    }
  },
  {
    path: '/schedule',
    name: 'EpisodeSchedule',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/EpisodeSchedule.vue'),
    meta: {
      title: 'Episode Schedule',
      requiresAuth: true
    }
  },
  {
    path: '/notes',
    name: 'Notes',
    component: () => import('../views/Notes.vue'),
    meta: {
      title: 'Notes',
      requiresAuth: true
    }
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('../views/Login.vue'),
    meta: {
      title: 'Login'
    }
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  if(to.matched.some(route => route.meta.requiresAuth)) {
    if(store.state.isAuthenticated !== true) {
      next("/login");
    } else {
      next();
    }
  }

  next();
})

export default router
